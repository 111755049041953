import React, { useState } from 'react';
import {
  Typography as T,
  Box,
  Button,
  TextInput,
  InputGroup,
  Form,
  Row,
  Card,
} from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import { useForm } from 'react-hook-form';
import axios from 'axios';

const RequestForm = () => {
  const { register, handleSubmit, formState } = useForm();
  const [customerName, setCustomerName] = useState();
  const [submitted, setSubmitted] = useState();
  const onSubmit = (form) => {
    const params = { ...form, message: `[Teamwork]: ${form.message}` };
    setCustomerName(form.firstName);
    axios
      .post('https://compus.hk/api/requestADemo', params)
      .then(function (res) {
        if (!res.error) setSubmitted(true);
        if (typeof window !== 'undefined' && window.gtag) {
          var callback = function () {
            if (typeof url != 'undefined') {
              window.location = url;
            }
          };
          window.gtag('event', 'conversion', {
            send_to: 'AW-964829434/aN8ICPvt-OMBEPrBiMwD',
            event_callback: callback,
          });
        }
      })
      .catch(function (error) {
        console.error(error);
      });
  };

  const SuccessView = ({ customerName }) => {
    return (
      <Card mt="l">
        <Row p="s" alignItems="center">
          <svg width="28px" height="28px" viewBox="0 0 163 160">
            <g
              id="Page-1"
              stroke="none"
              strokeWidth="1"
              fill="none"
              fillRule="evenodd"
            >
              <g id="Black_check" fill="#000000" fillRule="nonzero">
                <path
                  d="M0,109.129492 C0,109.129492 32.1203351,145.246773 38.5332492,160 L66.1366621,160 C77.7077898,124.718122 122.653953,40.5094418 161.192779,10.9918488 C169.167098,0.744231628 149.119771,-3.48847982 132.934133,3.30056657 C108.537177,13.520337 62.5314891,91.6305713 53.9437606,110.410444 C41.7592238,113.612824 28.9055133,89.8873625 28.8943604,89.8873625 L0,109.129492 Z"
                  id="Path"
                ></path>
              </g>
            </g>
          </svg>
          <T.H3 ml="m" mt={0}>
            Thank you!
          </T.H3>
        </Row>
        <T.P ml="m">
          <strong>Hi {customerName}</strong>, our team is preparing and will
          respond as soon as we can.
        </T.P>
      </Card>
    );
  };

  return (
    <Box width="100%" maxWidth={[null, null, 640, null]} mx="auto" mt="l">
      {submitted ? (
        <SuccessView customerName={customerName} />
      ) : (
        <form onSubmit={handleSubmit(onSubmit)}>
          <InputGroup direction="row" width="100%">
            <Form.Item name="firstName" label="First Name" flex={1}>
              <TextInput
                placeholder="First Name"
                ref={register({ required: true })}
              />
            </Form.Item>
            <Form.Item
              name="lastName"
              label="Last Name"
              mt="0 !important"
              flex={1}
            >
              <TextInput
                placeholder="Last Name"
                ref={register({ required: true })}
              />
            </Form.Item>
          </InputGroup>
          <Form.Item name="email" label="Email" mt="m">
            <TextInput
              placeholder="john@email.com"
              ref={register({ required: true })}
            />
          </Form.Item>
          <Form.Item name="phone" label="Phone">
            <TextInput
              placeholder="mobile no."
              ref={register({ required: true })}
            />
          </Form.Item>
          <Form.Item name="companyName" label="Name of your organization">
            <TextInput ref={register({ required: true })} />
          </Form.Item>
          <Form.Item name="message" label="Message" mt="s">
            <TextInput multiline ref={register()} />
          </Form.Item>
          <Box textAlign="center">
            <Button
              type="submit"
              size="l"
              mx="auto"
              mt="l"
              width="10rem"
              disabled={formState.isSubmitting}
            >
              Send
            </Button>
          </Box>
        </form>
      )}
    </Box>
  );
};

export default RequestForm;
