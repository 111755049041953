import React, { useEffect } from 'react';
import { Typography as T, Box, Flex, Separator } from '@fuji-ui/core';
import { Wrapper } from '../components/layout';
import RequestForm from '../components/request-form';
import SEO from '../components/seo';
// import ImgOffice from '../images/talkbox_office.jpg';
import { StaticImage } from 'gatsby-plugin-image';

const ContactUsPage = () => {
  useEffect(() => {
    if (typeof window !== 'undefined' && window.gtag) {
      window.gtag('event', 'conversion', {
        send_to: 'AW-964829434/jGexCJ692N8BEPrBiMwD',
        transaction_id: '',
      });
    }
  }, []);

  return (
    <>
      <SEO title="Request" />
      <Box pt="xl">
        <Wrapper>
          <Box m="auto" textAlign="center" maxWidth={[null, 720, 720, null]}>
            <T.H1>Questions? Let us know 👋🏻</T.H1>
            <T.P>
              Based in Hong Kong, our team expert could help analyse your
              business and provide you with simple solutions. Please fill in the
              the contact form below and our representatives shall contact you
              very soon.
            </T.P>
          </Box>
          <RequestForm />
          <Separator space="l" />
          <Flex
            mt="xl"
            alignItems={['stretch', null, 'center', null]}
            flexDirection={['column', null, 'row', null]}
          >
            <Box flex={2} p="m">
              {/* <img src={ImgOffice} width="100%" /> */}
              <StaticImage
                src="../images/talkbox_office.jpg"
                alt=""
                style={{ width: '100%' }}
              />
            </Box>
            <Box flex={3} p="m">
              <T.H3>Our office address</T.H3>
              <T.P>
                20/F., Harbourside HQ, 8 Lam Chak Street, Kowloon Bay, Hong Kong
              </T.P>
              <T.P>
                <a href="https://goo.gl/maps/cWxyyVdtZzRcspyX6">
                  View from Google Map
                </a>
              </T.P>
            </Box>
          </Flex>
        </Wrapper>
      </Box>
    </>
  );
};

export default ContactUsPage;
